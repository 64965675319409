import React from "react"
import { Alert, Form, Spin } from "antd"
import { Email, Name, Submit, UTMParams } from "./Fields"
import { antdHelpers } from "@avldev/gatsby-active-campaign-forms"
import { formUrl } from "../../../config/forms"

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class OptIn extends React.Component {
  formRef = React.createRef(null)

  state = {
    formSubmitted: false,
    formSuccess: false,
  }

  componentDidMount() {
    this.props.form.validateFields()
  }

  handleSubmit = event => {
    event.preventDefault()

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      const { current: form } = this.formRef
      if (!form) return

      const onSubmitStart = () => {
        this.setState({
          buttonIcon: `loading`,
          name: values.name.split(` `)[0],
        })
      }

      const onSubmitEnd = status => {
        this.setState({ formSuccess: status === 200 })
      }

      antdHelpers.onSubmit(
        form,
        { onSubmitStart, onSubmitEnd },
        process.env.NODE_ENV
      )
    })
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form
    const nameError = isFieldTouched("name") && getFieldError("name")
    const emailError = isFieldTouched("email") && getFieldError("email")

    return (
      <>
        {!this.state.formSuccess ? (
          <Spin spinning={this.state.formSubmitted && !this.state.formSuccess}>
            <form
              action={formUrl}
              onSubmit={this.handleSubmit}
              ref={this.formRef}
              style={{ textAlign: `left`, width: `100%` }}
            >
              <input type="hidden" name="u" value="10" data-name="u" />
              <input type="hidden" name="f" value="10" data-name="f" />
              <input type="hidden" name="s" data-name="s" />
              <input type="hidden" name="c" value="0" data-name="c" />
              <input type="hidden" name="m" value="0" data-name="m" />
              <input type="hidden" name="act" value="sub" data-name="act" />
              <input type="hidden" name="v" value="2" data-name="v" />
              <input
                type="hidden"
                name="or"
                value="96b29049631168ea8217b65d8614999d"
                data-name="or"
              />
              {/* <UTMParams decorator={getFieldDecorator} /> */}
              <Name decorator={getFieldDecorator} validator={nameError} />
              <Email decorator={getFieldDecorator} validator={emailError} />
              <Submit
                id={this.props.gtmClickId}
                disabled={hasErrors(getFieldsError())}
              />
            </form>
          </Spin>
        ) : (
          <Alert
            message={`Nice!`}
            description="Thanks for signing up! We'll keep you up to date with our best offers, and will notify you 24 hours before the general public when game day room blocks are released."
            type="success"
            showIcon
          />
        )}
      </>
    )
  }
}

const OptInForm = Form.create({ name: "opt_in_form" })(OptIn)

export default OptInForm
