import React from "react"
import { Col, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import OptIn from "../components/Forms/OptIn"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import BookingBar from "../components/BookingBar/BookingBar"
import style from "../styles/pages/get-offers.module.scss"

export default ({ data: { graphic, icon } }) => (
  <Shell>
    <Meta
      seo={{
        title: "Be An Insider | Lakeside Lodge Clemson",
        img: `https:${graphic.file.url}`,
        imgAlt: graphic.description,
      }}
      uri="/get-offers/"
    />
    <Row
      align="middle"
      className={style.heroWrapper}
      justify="center"
      type="flex"
    >
      <div className={style.border} />
      <Col className={style.heroCol} xs={20} md={16} lg={12}>
        <img
          alt={icon.description}
          className={style.icon}
          src={icon.file.url}
        />
        <br />
        <span className={style.title}>Be An Insider</span>
        <br />
        <span className={style.description}>
          Sign up to have exclusive savings and offers,{" "}
          <strong className={style.strong}>
            including 24 hours of early access to reservations on game day
            weekends
          </strong>
          , delivered right to your email inbox! Unsubscribe any time!
        </span>
        <br />
      </Col>
    </Row>
    <div className={style.bodyWrapper}>
      <Row align="top" id="form" justify="center" type="flex">
        <Col className={style.form} xs={23} sm={22} md={18} lg={14}>
          <Typography.Title level={2}>Sign Up Now</Typography.Title>
          <OptIn
            automationId={36}
            formName="Email Offers"
            gtmClickId="get-email-offers-form"
          />
        </Col>
      </Row>
      <Row
        align="middle"
        justify="center"
        type="flex"
        style={{ marginTop: 64 }}
      >
        <Col xs={22} md={20} lg={18} xl={16}>
          <SeoHeader
            seo={{ level: 1, content: "Early Access" }}
            human={{ level: 4, content: "First Dibs On Game Day Bookings." }}
          />
          <Typography.Paragraph>
            From location to amenities, Lakeside Lodge was designed to be
            Clemson's premier game day destination. But with limited space and
            high demand, only a select few will be lucky enough to stay in the
            center of it all. As an Insider, you'll be notified when blocks of
            rooms are released for game day weekends a full 24-hours before the
            general public — crucial time to snag some of the best digs in town.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row align="middle" justify="center" type="flex">
        <Col xs={22} md={20} lg={18} xl={16}>
          <SeoHeader
            seo={{ level: 2, content: "Exclusive Savings" }}
            human={{ level: 4, content: "Special Insider-Only Promos." }}
          />
          <Typography.Paragraph>
            Lakeside Lodge Clemson is far more than game day weekend HQ. As
            Tiger Country's only resort-style hotel, Lakeside Lodge is the
            perfect year-round destination for families, friends, or even
            corporate retreats. And as an Insider, you'll gain access to
            exclusive savings and promos, allowing you and yours to enjoy
            everything Lakeside Lodge has to offer — for less.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row align="middle" justify="center" type="flex">
        <Col xs={22} md={20} lg={18} xl={16}>
          <SeoHeader
            seo={{ level: 3, content: "Sign Up" }}
            human={{ level: 4, content: "Ready To Get Started?" }}
          />
          <Typography.Paragraph>
            Like Clemson, Lakeside Lodge is all about community. Join the
            growing ranks of Tiger Nation —{" "}
            <a className={style.enroll} href="#form">
              become an Insider today!
            </a>
          </Typography.Paragraph>
        </Col>
      </Row>
    </div>
    <div style={{ marginBottom: -32, marginTop: 48 }}>
      <BookingBar showCTAButtons={false} />
    </div>
  </Shell>
)

export const query = graphql`
  {
    graphic: contentfulAsset(contentful_id: { eq: "1ZEM3oX1jiSYkLndAnWeil" }) {
      description
      file {
        url
      }
    }
    icon: contentfulAsset(contentful_id: { eq: "4f94NgzYFepFqEGbH3S4ho" }) {
      description
      file {
        url
      }
    }
  }
`
